<template>
    <section id="section-home">
        <div id="main-bg-image" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg2.png') + `)`">
        </div>
        <div id="main-bg-quote" class="bgimg"
            :style="`background-image: url(` + require('@/assets/images/home/bg-quote.png') + `)`">
        </div>
        <!--
        <div id="main-bg-quote-attr" class="bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg-quote-attr.png') + `)`">
        </div>


        -->

        <div class="quotes-holder-outer">
            <!--<QuotesHolder :quotes="quotes"></QuotesHolder>-->
        </div>

        <div id="main-bg-image-mobile" >
            <img src="@/assets/images/home/bg-mobile.jpg"/>
        </div>
        <div class="con">

            <div class="panel-outer">
                <div class="panel">
                    <div class="panel2">
                        <div class="tt-fox stagger1">
                            <img src="@/assets/images/home/tt-fox.png"/>
                        </div>
                        <div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>
                        <div class="dates stagger1">
                            <div class="l-small style2" v-html="thedate"></div>
                            <div class="l-small">
                                <button @click="openAction('vod')" v-html="thedateButton">
                                </button>
                                <!--<button @click="openAction('theaters')">
                                    get tickets
                                </button>
                                -->
                            </div>
                            <!--
                            <div class="l-big">NOW PLAYING</div>
                            -->
                            <!--
                            <div class="l-small">
                                <button @click="openAction">
                                    watch now on demand
                                </button>
                            </div>
                            -->
                        </div>


                        <button class="ytplayer stagger1 Zdeactivated" @click="gh.openOverlay('video')">
                            <!--
                            <div class="bg0">
                                <img class="bg-frame0" src="@/assets/images/home/player-frame.png"/>
                            </div>
                            -->
                            <div class="bg">
                                <img class="bg-frame" src="@/assets/images/home/player.jpg"/>
                            </div>
                            <div class="icon stagger1">
                                <div class="icon2">
                                    <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                                </div>
                            </div>
                        </button>
                        <!--
                        <div class="l3 style2 stagger1">Sign up for updates & exclusive content.</div>
                        <div class="l3 signup stagger1">
                            <EmailSignup
                                mcDomain="https://lullabymovieofficial.us20.list-manage.com"
                                mcUserId="b25b645d846865015940afb4d"
                                mcListId="f713ffe268"
                            ></EmailSignup>
                        </div>
                        -->
                        <div class="l3 button-row stagger1">
                            <div class="button-row-l">
                                <span>#Subservience</span>
                            </div>
                            <!--
                            <div class="button-row-r">
                                <a href="https://www.dropbox.com/scl/fo/xqrru9a879puiqxm84efi/AA_fxLqHdMUumpqy2PB3L8M?rlkey=iv59kp4ylmk12kn06xq8itq2k&e=1&st=56j43q44&dl=0" target="_blank" class="btn-carrot full-width">
                                    <span>PRESS/EPK</span>
                                </a>
                            </div>
                            -->
                        </div>
                        <div class="l3 left-side-icons stagger1">
                            <div class="social-icons">
                                <div class="icon studio">
                                    <a href="https://www.xyzfilms.com/" target="_blank">
                                        <img src="@/assets/images/home/xyz.png"/>
                                    </a>
                                </div>
                                <div class="icon studio" style="top:-10px;width: 100px;height: 100px;">
                                    <a href="https://millennium-media.net/" target="_blank">
                                        <img src="@/assets/images/home/millenium.png"/>
                                    </a>
                                </div>
                                <!--
                                <div class="icon studio">
                                    <a href="https://traumaoneentertainment.com/" target="_blank">
                                        <img src="@/assets/images/home/logo-trauma-one.png"/>
                                    </a>
                                </div>

                                <div class="icon">
                                    <a href="https://twitter.com/welcomevillain" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/twitter.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.facebook.com/WelcomeVillain/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/facebook-f.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.instagram.com/welcomevillain/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/instagram.svg')"/>
                                    </a>
                                </div>
                                <div class="icon">
                                    <a href="https://www.youtube.com/@welcomevillain?sub_confirmation=1" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/youtube.svg')"/>
                                    </a>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2">
                    <!--
                    <div class="bgimg"
                         :style="`background-image: url(` + require('@/assets/images/popup/tt-bg.jpg') + `)`">
                    </div>
                    -->
                    <div @click="closeAction" class="close-button-outer">
                        <div class="close-button"></div>
                    </div>
                    <div class="order-inner3">
                        <div class="tt">
                            <img src="@/assets/images/home/tt.png"/>
                        </div>

                        <div v-if="this.bugsType==='theaters'" id="bugs-theaters" class="bugs">

                            <button class="bug xwide xnum-1">
                                <a href="https://drafthouse.com/nyc/show/backspot" target="_blank">
                                    <img src="@/assets/images/order/alamo.png"/>
                                </a>
                            </button>
                            <button class="bug xwide xnum-1">
                                <a href="https://www.circlecinema.org/" target="_blank">
                                    <img src="@/assets/images/order/circlecinema.png"/>
                                </a>
                            </button>

                        </div>
                        <div v-if="this.bugsType==='vod'" id="bugs-vod" class="bugs">

                            <button class="bug block">
                                <a href="https://watch.amazon.com/detail?gti=amzn1.dv.gti.0bd64e0b-763d-41dc-9223-0fbfff550edb&territory=US&ref_=share_ios_movie&r=web" target="_blank">
                                    <img src="@/assets/images/order/prime.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://tv.apple.com/us/movie/subservience/umc.cmc.3p39mkx1zt2xfx6tc4fb55rzy"
                                   target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.vudu.com/content/browse/details/Subservience/3327441"
                                   target="_blank">
                                    <img src="@/assets/images/order/fandango-at-home.png"/>
                                </a>
                            </button>

                            <!--
                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/itunes.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/amazon.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.amazon.com/Backspot-Devery-Jacobs/dp/B0CW4ZC4M5" target="_blank">
                                    <img src="@/assets/images/order/amazon.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://tv.apple.com/us/movie/backspot/umc.cmc.6n9zfqyt5eq9msu4ej1qsbhxd"
                                   target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.xfinity.com/stream/entity/7518903800086714112" target="_blank">
                                    <img src="@/assets/images/order/xfinity.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.vudu.com/content/browse/details/Backspot/3150395" target="_blank">
                                    <img src="@/assets/images/order/fandango-at-home.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://play.google.com/store/movies/details/Backspot?id=bHzAf9a8ccw.P" target="_blank">
                                    <img src="@/assets/images/order/google.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://ondemand.spectrum.net/movies/27257837/backspot/" target="_blank">
                                    <img src="@/assets/images/order/spectrum.svg"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://tv.verizon.com/watch/movie/Backspot/MV021993720000?idType=TMS&qualifiedId=TMS-MV021993720000" target="_blank">
                                    <img src="@/assets/images/order/verizon.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.microsoft.com/en-US/p/_/8D6KGWXX1FX9" target="_blank">
                                    <img src="@/assets/images/order/microsoft.png"/>
                                </a>
                            </button>



                             <button class="bug comingsoon">
                                <img src="@/assets/images/order/microsoft.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/itunes.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/vudu.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug">
                                <a href="https://tv.apple.com/movie/mind-body-spirit/umc.cmc.22r86hpogqaoxbg340icpqy0i?itsct=tv_box_link&itscg=30200" target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>





                            <button class="bug">
                                <a href="https://www.vudu.com/content/browse/details/Mind-Body-Spirit/3111538" target="_blank">
                                    <img src="@/assets/images/order/vudu.png"/>
                                </a>
                            </button>

                            <button class="bug">
                                <a href="https://www.microsoft.com/en-us/p/mind-body-spirit/8d6kgwxww0wk?activetab=pivot%3aoverviewtab" target="_blank">
                                    <img src="@/assets/images/order/xbox.png"/>
                                </a>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/amazon.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/google.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>



                            <button class="bug">
                                <a href="https://www.regmovies.com/movies/hunt-her-kill-her/ho00013977#/" target="_blank">
                                    <img src="@/assets/images/order/regal.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.amctheatres.com/movies/hunt-her-kill-her-71905" target="_blank">
                                    <img src="@/assets/images/order/amc.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.fandango.com/hunt-her-kill-her-2023-231039/movie-overview?date=2023-03-02"
                                   target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.laemmle.com/film/hunt-her-kill-her#get-tickets"
                                   target="_blank">
                                    <img src="@/assets/images/order/lamelle.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.readingcinemasus.com/rohnertpark/film/hunt-her-kill-her"
                                   target="_blank">
                                    <img src="@/assets/images/order/reading.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.consolidatedtheatres.com/kaahumanu"
                                   target="_blank">
                                    <img src="@/assets/images/order/consolidated.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href=" https://lumierecinemala.com/film-hunt-her-kill-her/"
                                   target="_blank">
                                    <img src="@/assets/images/order/lumiere.png"/>
                                </a>
                            </button>
                            -->

                        </div>
                        <div class="hash">
                            #Subservience
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
//import EmailSignup from '@/components/blocks/EmailSignup'
//import QuotesHolder from '@/components/blocks/QuotesHolder'

export default {
    name: "HomePage",
    components: {
        //EmailSignup,
        //QuotesHolder
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            bugsType: null,
            quotes: [
                {
                    quote: "",
                    source: ""
                },
            ]
        }
    },
    mounted() {
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    computed: {
      thedate() {
          let today = new Date()
          //today = new Date('2024-09-9')
          let ret = ''
          if (today >= new Date('2024-09-13')) {
              ret = 'NOW PLAYING ON DIGITAL';
          } else if (today >= new Date('2024-09-12')) {
              ret = 'ON DIGITAL TOMORROW';
          } else if (today >= new Date('2024-09-09')) {
              ret = 'ON DIGITAL FRIDAY';
          } else {
              ret = 'ON DIGITAL SEPTEMBER 13TH';
          }
          return ret
      },
        thedateButton() {
            let today = new Date()
            let ret = ''
            if (today >= new Date('2024-09-13')) {
                ret = 'WATCH NOW';
            } else {
                ret = 'PREORDER NOW';
            }
            return ret
        },
    },

    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction(type) {
            this.bugsType = type
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        }
    }
}
</script>